import React, { useContext, useEffect, useRef, useState } from "react"
import { Table, Row, Col, Form, Spinner, Button } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import { commafy } from "../../utils/Utils"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import ModalProduct from "./ModalProduct"

const $ = require("jquery")
$.Datatable = require("datatables.net")

const Transaction = () => {
  let ref = useRef()
  let refTujuan = useRef()
  let refProduk = useRef()
  let refReseller = useRef()
  let refLimit = useRef()
  let refHistory = useRef()
  let refSupplier = useRef()
  useEffect(() => {}, [])
  const { api, setSpinner } = useContext(AppContext)
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState("Semua")
  const [showModal, setShowModal] = useState({
    show: false,
    id: "",
    row: { supplier: {} },
  })

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    getData()
  }, [])

  const getData = async () => { 
    setSpinner(true)
    let param = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
      status,
      tujuan: refTujuan.value,
      produk: refProduk.value,
      reseller: refReseller.value,
      supplier: refSupplier.value,
      limit: refLimit.value,
      history : refHistory.checked
    }
    const data = await api("get", "transaction", param)
    if (data.success) {
      setData(data.data)
    }
    setSpinner(false)
  }

  useEffect(() => {
    const element = $(ref)
    if ($.fn.dataTable.isDataTable(element)) {
      element.DataTable().destroy()
    }
    let initial = {
      columns: [
        { title: "" },
        { title: "Trx Id" },
        { title: "Tanggal" },
        { title: "Update" },
        { title: "No Tujuan" },
        { title: "Produk" },
        { title: "Supplier" },
        { title: "Kode Produk" },
        { title: "Harga " },
        { title: "Harga Margin" },
        { title: "Status " },
        { title: "Status Supplier" },
        { title: "Ket/SN " },
        { title: "Notes " },
        { title: "Notes Supplier" },
        { title: "Koneksi " },
        { title: "Reseller " },
        { title: "Trx Id Reseller" },
        { title: "Saldo Awal" },
        { title: "Saldo Akhir" },
      ],
      data: [],
      aaSorting: [],
      paging: false,
      bFilter: false,
      bPaginate: false,
      bLengthChange: false,
      bFilter: false,
      bInfo: false,
      bAutoWidth: false,

      fixedColumns: {
        left: 0,
        right: 0,
      },
      fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        if (aData[10] == "Gagal") {
          $("td", nRow).css("background-color", "#FAA0A0")
        }
        if (aData[10] == "Proses") {
          $("td", nRow).css("background-color", "#ACD1AF")
        }
      },
      columnDefs: [
        {
          targets: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19,
          ],
          orderable: false,
        },
        {
          targets: [0],
          className: "text-center",
          render: function (data, type, row, meta) {
            return `<div> 
            <input id="check" type="checkbox"></input>
             </div>`
          },
        },
      ],
      autoWidth: false,
      pageLength: 10,
      fixedColumns: true,
    }
    let obj = []
    data.forEach((item, key) => {
      let tmp = [
        "",
        item.trxid,
        item.createdAt,
        item.updatedAt,
        item.tujuan,
        item.product_name,
        item.supplier.name,
        item.code,
        commafy(item.price),
        commafy(item.price_margin),
        item.status,
        item.status_supplier,
        item.sn,
        item.note,
        item.note_supplier,
        item.connection.toUpperCase(),
        item.user.first_name,
        item.trxid_reseller,
        commafy(item.saldo_awal),
        commafy(item.saldo_akhir),
      ]
      obj.push(tmp)
    })

    initial.data = obj
    let table = element.DataTable(initial)

    element.on("click", "tr", function (e) {
      let dataRow = table.row(this).data()
      let idx = $(this).closest("tr").index()
      if (e.target.id === "check" && dataRow) {
        var checkbox = table.$('input[id="check"]')[idx]
        if (checkbox.checked) {
          table.$('input[id="check"]').map((item) => {
            if (item !== idx) table.$('input[id="check"]')[item].checked = false
          })
        }
        setShowModal({
          row: dataRow,
          show: checkbox.checked,
          refresh: false,
        })
      }
    })
  }, [data])

  const filter = () => {
    getData()
  }

  useEffect(() => {
    if (showModal.refresh) getData()
  }, [showModal])

  return (
    <div className='p-3'>
      <ModalProduct show={showModal} setShow={setShowModal} />
      <Row>
        <div className='font-11'>
          <div className='font-13 font-w-600'>
            <span>Transaction</span>
          </div>

          <div className='mt-2'>
            <Row className='g-2'>
              <Col>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  className='form-control font-11 shadow-none'
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  popperPlacement='bottom'
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                    },
                  }}
                />
              </Col>
              <Col>
                <Form.Select
                  onChange={(e) => setStatus(e.target.value)}
                  className='font-11 shadow-none'>
                  <option>Semua</option>
                  <option value='Proses'>Proses</option>
                  <option value='Success'>Success</option>
                  <option value='Gagal'>Gagal</option>
                  <option value='Dialihkan'>Dialihkan</option>
                  <option value='Menunggu Pembayaran'>
                    Menunggu Pembayaran
                  </option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Control
                  name='supplier'
                  ref={(el) => (refSupplier = el)}
                  type='text'
                  placeholder='Supllier'
                  className='font-11 shadow-none text-dark'
                />
              </Col>
              <Col>
                <Form.Control
                  name='tujuan'
                  ref={(el) => (refTujuan = el)}
                  type='number'
                  placeholder='Tujuan'
                  className='font-11 shadow-none text-dark'
                />
              </Col>
              <Col>
                <Form.Control
                  name='produk'
                  ref={(el) => (refProduk = el)}
                  type='text'
                  placeholder='Produk'
                  className='font-11 shadow-none text-dark'
                />
              </Col>
              <Col>
                <Form.Control
                  name='reseller'
                  ref={(el) => (refReseller = el)}
                  type='text'
                  placeholder='Reseller'
                  className='font-11 shadow-none text-dark'
                />
              </Col>
              <Col>
                <Form.Control
                  name='limit'
                  ref={(el) => (refLimit = el)}
                  defaultValue={100}
                  type='text'
                  placeholder='Limit'
                  className='font-11 shadow-none text-dark'
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="History"  
                  ref={(el) => (refHistory = el)}
                  defaultChecked={false}
                  className="mt-2 mr-0 pr-0"
                />
              </Col>
              <Col>
                <Button
                  variant='primary'
                  className='text-white font-10 shadow-none'
                  disabled={isLoading}
                  onClick={() => filter()}
                  type='button'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      className='font-10'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    "Filter"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
          <div className='mt-3' style={{ height: "77vh", overflow: "scroll" }}>
            <div>
              <Table
                id='table-transaksi'
                className='font-11'
                style={{ width: "100%" }}
                bordered
                hover
                ref={(el) => (ref = el)}></Table>
            </div>
          </div>
          <div className='mt-1'>
            <Row>
              <Col>
                <span>Jumlah Data : {data.length}</span>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Transaction
