import React, { useEffect, useState, useContext } from "react"
import { Col, Row, Spinner, Button } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import { BasicChart } from "../../components/highcharts/Charts"
import _ from "lodash"

const Transaction = ({ month }) => {
  const { api, setSpinner } = useContext(AppContext)
  const [charts, setCharts] = useState(false)

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }

    getData()
  }, [month])

  const getData = async () => {
    setSpinner(true)
    const data = await api("get", "transaction/summary-transaction", {
      month: month.month + 1,
      year: month.year,
      day: month.day,
      live : month.live
    })
    if (data.success) {
      let categories = _.map(data.data.qty, "date")
      let transaction = _.map(data.data.qty, "transaction")
      let qty = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.qtyMonthly, "date")
      transaction = _.map(data.data.qtyMonthly, "transaction")
      let qtyMonthly = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.nominal, "date")
      transaction = _.map(data.data.nominal, "transaction")
      let nominal = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.nominalMonthly, "date")
      transaction = _.map(data.data.nominalMonthly, "transaction")
      let nominalMonthly = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.untung, "date")
      transaction = _.map(data.data.untung, "transaction")
      let untung = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Keuntungan",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.untungBulan, "date")
      transaction = _.map(data.data.untungBulan, "transaction")
      let untungBulan = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Keuntungan",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.qtyHourly, "date")
      transaction = _.map(data.data.qtyHourly, "transaction")
      let qtyHourly = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      categories = _.map(data.data.nominalHourly, "date")
      transaction = _.map(data.data.nominalHourly, "transaction")
      let nominalHourly = {
        type: "column",
        categories: categories,
        legend: {
          enabled: false,
          itemStyle: {
            fontSize: "10px",
            fontWeight: "normal",
            color: "black",
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        plotOptions: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        data: [
          {
            data: transaction,
            color: "#0099cc",
            name: "Jumlah Transaksi",

            marker: {
              enabled: false,
            },
          },
        ],
      }

      setCharts({
        qty,
        nominal,
        nominalMonthly,
        qtyMonthly,
        untung,
        untungBulan,
        qtyHourly,
        nominalHourly,
      })
    }
    setSpinner(false)
  }

  return (
    <div className='m-3'>
      <Row>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Hourly (Qty)</span>
            <div className='border-top mt-2 '>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.qtyHourly} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Hourly (Nominal)</span>
            <div className='border-top mt-2'>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.nominalHourly} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Daily (Qty)</span>
            <div className='border-top mt-2'>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.qty} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Daily (Nominal)</span>
            <div className='border-top mt-2'>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.nominal} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Monthly (Qty)</span>
            <div className='border-top mt-2 '>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.qtyMonthly} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Transaksi Monthly (Nominal)</span>
            <div className='border-top mt-2'>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.nominalMonthly} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='pb-5 mt-3'>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Keuntungan Daily</span>
            <div className='border-top mt-2 '>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.untung} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md='6'>
          <div className='font-12 shadow p-3'>
            <span className='w-100'>Keuntungan Monthly</span>
            <div className='border-top mt-2'>
              <div
                className='font-12 mt-2 d-flex justify-content-center align-items-center'
                style={{ height: "200px" }}>
                {charts ? (
                  <BasicChart charts={charts.untungBulan} />
                ) : (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Transaction
