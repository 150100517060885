export const stripHtml = (text) => {
  if (text === "" || text === undefined || text === null) return ""
  return text.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, "")
}

export const stripHtmlAnd = (text) => {
  if (text === "" || text === undefined || text === null) return ""
  return text
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&nbsp;/g, "")
}

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
}

export const asyncRequest = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

export const initialString = (text) => {
  if (text === "" || text === undefined || text === null) return ""
  return text
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase()
}

export const checkExpiredLogin = () => {
  let localData = JSON.parse(localStorage.getItem("admin"))
  if (
    !localData ||
    localData.expired == null ||
    localData.expired < new Date().getTime()
  ) {
    localStorage.removeItem("admin")
    return false
  }
  return true
}

export const checkExpiredLoginUser = () => {
  let localData = JSON.parse(localStorage.getItem("usersiffa"))
  if (
    !localData ||
    localData.expired == null ||
    localData.expired < new Date().getTime()
  ) {
    localStorage.removeItem("usersiffa")
    return false
  }
  return true
}

export const getLocalStorage = () => {
  return JSON.parse(localStorage.getItem("usersiffa"))
}

export const showImage = (name) => {
  let pathUrl =
    "https://gameshub.acceleratestudio.net/api/v1/images/uploads/" + name
  if (window.location.hostname === "localhost")
    pathUrl = "http://localhost:3333/api/v1/images/uploads/" + name
  return pathUrl
}

export const commafy = (num) => {
  if (num === 0) return "0"
  if (!num || num === undefined || num === isNaN) return "-"
  var str = num.toString().split(".")
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ")
  }
  return str.join(".")
}

export const getMonthSubs = (value) => {
  if (!value || value === undefined || value === null || value === isNaN)
    return "-"
  let month = value.toString().substring(4, 6).toString()
  let year = value.toString().substring(0, 4).toString()
  return year + "-" + month
}

export const getMonthName = (value) => {
  let monthName = []
  monthName[""] = "-"
  monthName["01"] = "JAN"
  monthName["02"] = "FEB"
  monthName["03"] = "MAR"
  monthName["04"] = "APR"
  monthName["05"] = "MEI"
  monthName["06"] = "JUN"
  monthName["07"] = "JUL"
  monthName["08"] = "AUG"
  monthName["09"] = "SEP"
  monthName["10"] = "OCT"
  monthName["11"] = "NOV"
  monthName["12"] = "DEC"

  return monthName[value]
}

export const getDateSubs = (value) => {
  if (!value) return
  let date = value.slice(-2)
  return date
}
