import React from "react"
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom"
import Login from "../pages/auth/Login"
import Register from "../pages/auth/Register"
import Home from "../pages/home/Home"

export const MainRoutes = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/app/dashboard' />
          </Route>
          <Route path='/app'>
            <Home />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route exact path='/register'>
            <Register />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  )
}
