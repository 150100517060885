import React, { useContext, useEffect, useState } from "react"
import { Col, Form, Row, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
// Hook Forms
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { NotificationManager } from "react-notifications"

const Domino = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const { api, setSpinner } = useContext(AppContext)
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ mode: "onBlur" })

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    // getData()
  }, [])

  const getData = async () => {
    setSpinner(true)
    let data = await api("get", "setting", { source: "kiosgamer" })
    if (data.success) {
      setData(data.data)
      setValue("session", data.data.session, {
        shouldValidate: false,
      })
    }
    setSpinner(false)
  }

  const onSubmitStock = async (params) => {
    let infullType = 80194
    if (params.type === "22") infullType = 80191
    Object.assign(params, { infullType })

    setLoading(true)
    const [data] = await Promise.all([
      api("post", "transaction-h2h/domino-stock", params),
    ]) 
    window.open(data, "_blank").document.write(data)
    setLoading(false)
  }

  const onSubmitValidasi = async (params) => {
    let infullType = 80194
    if (params.type === "22") infullType = 80191
    Object.assign(params, { infullType })

    setLoading(true)
    const [dataHtml] = await Promise.all([
      api("post", "transaction-h2h/domino-stock", params),
    ]) 
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = dataHtml; 
     
    const [data] = await Promise.all([
      api("post", "transaction-h2h/domino-history", params),
    ])
    let newHtmlObject = document.createElement('ul');
    newHtmlObject.innerHTML = data; 
    htmlObject.getElementsByClassName("listContent")[0].appendChild(newHtmlObject)
  
    window.open(htmlObject.outerHTML, "_blank").document.write(htmlObject.outerHTML)
    setLoading(false)
  }

  const onSubmit = async (params) => {
    let infullType = 80194
    if (params.type === "22") infullType = 80191
    Object.assign(params, { infullType })

    setLoading(true)
    const [data, data2, data3, data4, data5] = await Promise.all([
      api("post", "transaction-h2h/domino", params),
      api("post", "transaction-h2h/domino", params),
      api("post", "transaction-h2h/domino", params),
      api("post", "transaction-h2h/domino", params),
      api("post", "transaction-h2h/domino", params),
    ])
    setLoading(false)
    if(data.includes("unipin") || data.includes("codapayments"))
    window.open(data, "_blank").focus()
    if(data2.includes("unipin") || data2.includes("codapayments"))
    window.open(data2, "_blank").focus()
    if(data3.includes("unipin") || data3.includes("codapayments"))
    window.open(data3, "_blank").focus()
    if(data4.includes("unipin") || data4.includes("codapayments"))
    window.open(data4, "_blank").focus()
    if(data5.includes("unipin") || data5.includes("codapayments"))
    window.open(data5, "_blank").focus()
   
  }

  return (
    <div className='p-3'>
      <Row>
        <Col md='6'>
          <div className='font-13 font-w-600'>
            <span>Link Domino</span>
          </div>

          <div className='mt-1'>
            <Form>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Akun</Form.Label>
                <Form.Select
                  {...register("user", {})}
                  className='font-11 shadow-none'>
                  {/* NARA */}
                  <option value='430393'>430393</option> 
                  <option value='484854'>484854</option> 
                  <option value='800350'>800350</option> 
                  <option value='601859'>601859</option> 
                  <option value='795710'>795710</option>
                  <option value='715403'>715403</option>
                  <option value='572284'>572284</option>
                  {/* APR */}
                  {/* <option value='715403'>715403</option> */}
                  {/* FY */}
                  {/* <option value='715403'>715403</option> */}
                </Form.Select>
              </Form.Group>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Tipe Link</Form.Label>
                <Form.Select
                  {...register("infullChannel", {})}
                  className='font-11 shadow-none'>
                  <option d>Pilih Tipe Link</option>
                  <option value='22'>Unipin</option>
                  <option value='6'>Codapay</option>
                </Form.Select>
              </Form.Group>{" "}
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Denom</Form.Label>
                <Form.Select
                  {...register("productId", {})}
                  className='font-11 shadow-none'>
                  <option>Pilih Denom</option>
                  <option value='51'>1M</option>
                  <option value='42'>60M</option>
                  <option value='44'>200M</option>
                  <option value='46'>400M</option>
                  <option value='58'>1B</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Tujuan</Form.Label> 
                <Form.Control
                  name='buyerId'
                  className='font-11 shadow-none'
                  {...register("buyerId" 
                  )}
                />
              </Form.Group> 
              <Row>
                <Col md='2'>
                  <Form.Group className='font-11 mt-3'>
                    <Button
                      variant='primary'
                      className='text-white font-11 shadow-none'
                      disabled={isLoading}
                      onClick={handleSubmit(onSubmit)}
                      type='submit'>
                      {isLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        "Generate"
                      )}
                    </Button>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group className='font-11 mt-3'>
                    <Button
                      variant='primary'
                      onClick={handleSubmit(onSubmitStock)}
                      value='2'
                      className='text-white font-11 shadow-none pl-3'
                      disabled={isLoading}
                      type='submit'>
                      {isLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        "Stock"
                      )}
                    </Button>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='font-11 mt-3'>
                    <Button
                      variant='primary'
                      onClick={handleSubmit(onSubmitValidasi)}
                      value='2'
                      className='text-white font-11 shadow-none pl-3'
                      disabled={isLoading}
                      type='submit'>
                      {isLoading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        "Validasi"
                      )}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Domino
