import React, { useContext, useState, useRef, useEffect } from "react"
import { Col, Form, Row, Button, Spinner, Table } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
// Hook Forms
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { NotificationManager } from "react-notifications"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import History from "./History"
import Intruction from "./Instruction"
import { commafy } from "../../utils/Utils"

const Balance = () => {
  const [isLoading, setLoading] = useState(false)
  const [isRefresh, setRefresh] = useState(true)
  const [saldo, setSaldo] = useState("Rp 0")
  const [showIntruction, setShowIntruction] = useState(false)
  const { api } = useContext(AppContext)
  const refParams = useRef({ nominal: "" })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ mode: "onBlur" })

  useEffect(() => {
    getSaldo()
  }, [])

  const onSubmit = async (params) => {
    if (params.bank === "Pilih Bank") {
      setError("type", {
        type: "manual",
        message: "Silahkan pilih Bank",
      })
      return
    }
    setLoading(true)
    let data = await api("post", "saldo-history/store", params)
    if (data.success) {
      NotificationManager.success(data.message)
      refParams.current = { nominal: data.data.nominal }
      setRefresh(true)
      setValue("nominal", "", { shouldValidate: false })
      setValue("account_name", "", { shouldValidate: false })
      setShowIntruction(true)
    } else
      setError("nominal", {
        type: "manual",
        message: data.message,
      })
    setLoading(false)
  }

  const getSaldo = async (params) => {
    let data = await api("get", "saldo-history/get-saldo", params)
    if (data.success) {
      setSaldo("Rp " + data.data.nominal)
    }
  }

  return (
    <div className='p-3'>
      <Row>
        <Col md='6'>
          <div className='font-13 font-w-600'>
            <span>Saldo Anda</span>
          </div>
          <Form.Control
            name='nominal'
            type='text'
            readOnly
            value={commafy(saldo)}
            className='font-11 shadow-none'
          />
          <div className='font-13 font-w-600 mt-3'>
            <span>Request Deposit</span>
          </div>

          <div className='mt-1'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Nominal</Form.Label>
                <Form.Control
                  name='nominal'
                  type='number'
                  className='font-11 shadow-none'
                  {...register("nominal", {
                    required: "Nominal is required",
                  })}
                />
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='nominal' />
              </div>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Bank</Form.Label>
                <Form.Select
                  {...register("bank", {})}
                  className='font-11 shadow-none'>
                  <option>Pilih Bank</option>
                  <option value='BCA'>BCA</option>
                </Form.Select>
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='type' />
              </div>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Nama Pemilik Rekening</Form.Label>
                <Form.Control
                  name='account_name'
                  className='font-11 shadow-none'
                  {...register("account_name", {
                    required: "Nama Pemilik Rekening is required",
                  })}
                />
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='account_name' />
              </div>
              <Form.Group className='font-11 mt-3'>
                <Button
                  variant='primary'
                  className='text-white font-11 shadow-none'
                  disabled={isLoading}
                  type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    "Simpan"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
        <Intruction
          showIntruction={showIntruction}
          params={refParams.current}></Intruction>
      </Row>

      <History
        isRefresh={isRefresh}
        setRefresh={(e) => setRefresh(e)}></History>
    </div>
  )
}

export default Balance
