import React, { useContext, useEffect, useState, useRef } from "react"
import { Row, Table } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import { commafy } from "../../utils/Utils"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import moment from "moment"

const $ = require("jquery")
$.Datatable = require("datatables.net")

const History = ({ isRefresh, setRefresh }) => {
  let ref = useRef()
  const [data, setData] = useState([])
  const { api, setSpinner } = useContext(AppContext)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  
  useEffect(() => {
    if (isRefresh) getData()
  }, [isRefresh])

  const getData = async () => {
    setSpinner(true)
    let param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
      order_by : "Desc"
    }
    let data = await api("get", "saldo-history/get", param)
    if (data.success) {
      setRefresh(false)
      setData(data.data)
    }
    setSpinner(false)
  }

  useEffect(() => {
    if (!data.length) return
    const element = $(ref)
    if (!element[0].id) return
    if (!$.fn.dataTable.isDataTable(element)) {
      element.DataTable({
        pageLength: 100,
        ordering: false,

        aLengthMenu: [
          [5, 10, 50, 100, 500, -1],
          [5, 10, 50, 100, 500, "All"],
        ],
        iDisplayLength: 100,
      })
    }
  }, [data])

  return (
    <Row>
      <div className='mt-2 font-11'>
        <hr></hr>
        <div className='font-13 font-w-600'>
          <span>History Request Deposit</span>
        </div>
        <div
          className='mt-3 pb-5'
          style={{ height: "100vh", overflow: "scroll" }}>
          <Table
            id='table-history'
            style={{ width: "100%" }}
            bordered
            hover
            ref={(el) => (ref = el)}>
            <thead>
              <tr>
                <th>No</th>
                <th>Reseller</th>
                <th>Bank</th>
                <th>Nama Rekening</th>
                <th>Nominal Request</th>
                <th>Nominal Transfer</th>
                <th>Status</th>
                <th>Expired</th>
                <th>Tgl Request</th>
                <th>Tgl Update</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{item.user ? item.user.first_name : "-"}</td>
                    <td>{item.bank}</td>
                    <td>{item.account_name}</td>
                    <td>{commafy(item.nominal)}</td>
                    <td>{commafy(item.nominal_uniq)}</td>
                    <td>{item.status}</td>
                    <td>{item.expired}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.updatedAt}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </Row>
  )
}

export default History
