import { MainRoutes } from "./router/Index"
import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import { AppContextProvider } from "./context/AppContext"

function App() {
  return (
    <Router>
      <AppContextProvider>
        <MainRoutes />
      </AppContextProvider>
    </Router>
  )
}

export default App
