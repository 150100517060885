import React, { useState, useEffect, useContext, useRef } from "react"
import { Modal, Nav, Table, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import _ from "lodash"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import { commafy } from "../../utils/Utils"
const $ = require("jquery")
$.Datatable = require("datatables.net")

const ModalSupplier = ({ show, setShow }) => {
  const { api, setSpinner } = useContext(AppContext)
  const [data, setData] = useState([])
  const [idCategory, setIdCategory] = useState("")
  const [brandActive, setBrandActive] = useState("")
  let ref = useRef()

  useEffect(() => {
    if (show.id) {
      console.log(show)
      let data = localStorage.getItem("usergameshubcloud") 
      getData()
    }
  }, [show])

  const getData = async () => {
    const data = await api("get", "product-supplier/get", { id: show.id })
    if (data.success) {
      setData(data.data)
    }
  }

  useEffect(() => {
    if (!data.length) return
    const element = $(ref)
    if (!element[0].id) return
    if ($.fn.dataTable.isDataTable(element)) {
      element.DataTable().destroy()
    }
    let initial = {
      columns: [
        { title: "Id" },
        { title: "SupplierId" },
        { title: "Supplier" },
        { title: "Code" },
        { title: "Harga" },
        { title: "Deskripsi" },
        { title: "Actions" },
      ],
      data: [],
      aaSorting: [],
      bPaginate: true,
      columnDefs: [
        {
          className: "dt-center",
          targets: [4],
        },

        {
          targets: [0, 1, 2, 3, 5, 6],
          orderable: false,
        },
        {
          targets: [0, 1],
          visible: false,
        },

        {
          targets: [6],
          className: "text-center",
          render: function (data, type, row, meta) {
            return `<div><button class="btn btn-primary text-white font-11 shadow-none" variant="primary" id="pilih">Pilih</button></div>`
          },
        },
      ],
      autoWidth: false,
      pageLength: 1000,
    }
    let obj = []
    data.forEach((item, key) => {
      let tmp = [
        item.id,
        item.supplier_id,
        item.supplier ? item.supplier.name : "-",
        item.item_code,
        commafy(item.price),
        item.desc,
        item.desc,
      ]
      obj.push(tmp)
    })

    initial.data = obj
    let table = element.DataTable(initial)
    element.on("click", "tr", function (e) {
      let dataRow = table.row(this).data()

      if (e.target.id === "pilih") setShow(false, dataRow, show.id)
    })
  }, [data])

  return (
    <Modal
      show={show.show}
      onHide={() => setShow(false)}
      dialogClassName='modal-90w'
      style={{ height: "100vh" }}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>Pilih Supplier</span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div
          className='mt-3 font-11'
          style={{ overflow: "scroll", height: "75vh" }}>
          <Table
            id='table-supplier'
            style={{ width: "100%" }}
            bordered
            hover
            ref={(el) => (ref = el)}></Table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalSupplier
