import React, { useState, useEffect, useContext, useRef } from "react"
import { Modal, Form, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import _ from "lodash"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { NotificationManager } from "react-notifications"

const ModalUpload = ({ show, setShow, setRefresh }) => {
  const { api, setSpinner } = useContext(AppContext)
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    setError,
  } = useForm({ mode: "onBlur" })

  const [denoms, setDenoms] = useState([
    {
      denom: "Free Fire 12 Diamond",
      label: "Free Fire 12 Diamond",
    },
    {
      denom: "Free Fire 50 Diamond",
      label: "Free Fire 50 Diamond",
    },
    {
      denom: "Free Fire 70 Diamond",
      label: "Free Fire 70 Diamond",
    },
    {
      denom: "Free Fire 140 Diamond",
      label: "Free Fire 140 Diamond",
    },
    {
      denom: "Free Fire 355 Diamond",
      label: "Free Fire 355 Diamond",
    },
    {
      denom: "MOBILELEGEND - 28 Diamond",
      label: "MOBILELEGEND - 28 Diamond",
    },
  ])

  const onSubmit = async (params) => {
    if (params.game === "pilih") {
      setError("game", {
        type: "manual",
        message: "Silahkan pilih Game",
      })
      return
    }
    if (params.denom === "pilih") {
      setError("denom", {
        type: "manual",
        message: "Silahkan pilih Denom",
      })
      return
    }
    if (params.source === "pilih") {
      setError("source", {
        type: "manual",
        message: "Silahkan pilih Source",
      })
      return
    }

    let formData = new FormData()
    formData.append("game", params.game)
    formData.append("denom", params.denom)
    formData.append("source", params.source)
    formData.append("file", params.file[0])

    let headers = {
      "content-type": "multipart/form-data",
    }
    setLoading(true)
    let upload = await api("post", "voucher/upload", formData, headers)
    if (upload.success) {
      NotificationManager.success(upload.message)
      setRefresh(true)
      setShow(false)
    } else NotificationManager.error(upload.message)

    setLoading(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      style={{ height: "100vh" }}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>Upload Voucher</span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group className='font-11'>
            <Form.Label>Game</Form.Label>
            <Form.Select
              {...register("game", {
                required: "Game is required",
              })}
              className='font-11 shadow-none'>
              <option value='pilih' defaultChecked={true}>
                Pilih Game
              </option>
              <option value='Free Fire'>Free Fire</option>
              <option value='Mobile Legend'>Mobile Legend</option>
            </Form.Select>
            <div className='mt-1 text-left font-11 text-danger'>
              <ErrorMessage errors={errors} name='game' />
            </div>
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Denom</Form.Label>
            <Form.Select
              className='font-11 shadow-none'
              {...register("denom", {
                required: "Denom is required",
              })}>
              <option value='pilih' defaultChecked={true}>
                Pilih Denom
              </option>
              {denoms.map((item, key) => {
                return (
                  <option key={key} value={item.denom}>
                    {item.label}
                  </option>
                )
              })}
            </Form.Select>
            <div className='mt-1 text-left font-11 text-danger'>
              <ErrorMessage errors={errors} name='denom' />
            </div>
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Source</Form.Label>
            <Form.Select
              className='font-11 shadow-none'
              {...register("source", {
                required: "Source is required",
              })}>
              <option value='pilih' defaultChecked={true}>
                Pilih Source
              </option>
              <option value='Dunia Games'>Dunia Games</option>
            </Form.Select>
            <div className='mt-1 text-left font-11 text-danger'>
              <ErrorMessage errors={errors} name='source' />
            </div>
          </Form.Group>
          <Form.Group controlId='formFile' className='font-11 mt-2'>
            <Form.Label>File</Form.Label>
            <Form.Control
              type='file'
              className='font-11'
              accept='.csv'
              {...register("file", {})}
            />
          </Form.Group>
          <div className='mt-1 text-left font-11 text-danger'>
            <ErrorMessage errors={errors} name='file' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            className='shadow-none font-13'
            onClick={() => setShow(false)}>
            Batal
          </Button>
          <Button
            variant='primary'
            className='text-white font-11 shadow-none'
            disabled={isLoading}
            type='submit'>
            {isLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalUpload
