import React, { useState, useEffect, useContext, useRef } from "react"
import { Modal, Nav, Table, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import _ from "lodash"
import { asyncRequest } from "../../utils/Utils"
import NotificationManager from "react-notifications/lib/NotificationManager"

const ModalAdd = ({ show, setShow, data }) => {
  const { api, setSpinner } = useContext(AppContext)
  const [dataProductsAll, setDataProductsAll] = useState([])
  const [dataProducts, setDataProducts] = useState([])
  const [idCategory, setIdCategory] = useState("")
  const [dataType, setDataType] = useState([])
  let ref = useRef("")
  const [typeActive, setTypeActive] = useState("")

  useEffect(() => {
    if (show) {
      setDataType([])
      getDataProduct(data.category, data.brand, data.type)
    }
  }, [show])

  const getDataProduct = async (category_id, brand_id, type_id) => {
    setDataProducts([])
    const data = await api("get", "products/get", {
      category_id: category_id,
      brand_id: brand_id,
      type_id: type_id,
    })
    if (data.success) {
      let type = []
      data.data.map((item) => {
        if (type.indexOf(item.types.name) === -1) type.push(item.types.name)
      })
      setTypeActive(type[0])
      setDataType(type)
      filterData(data.data, type[0])
      setDataProductsAll(data.data)
    }
  }

  const actionAdd = async (item) => {
    let tmpData = _.cloneDeep(dataProducts)
    tmpData.map((it) => {
      it.isLoading = false
      if (it.id === item.id) it.isLoading = true
    })
    setDataProducts(tmpData)

    let add = await api("post", "produk/store", { id: item.id })

    tmpData = _.cloneDeep(dataProducts)
    tmpData.map((it) => {
      it.isLoading = false
    })
    asyncRequest(100).then(() => {
      setDataProducts(tmpData)
      if (add.success) {
        NotificationManager.success(add.message, "Sukses", 800)
      } else NotificationManager.error(add.message, "Sukses", 800)
    })
  }

  const handleSelectType = (item) => {
    setTypeActive(item)
    filterData(dataProductsAll, item)
  }

  const filterData = (data, active) => {
    let tmpData = []
    data.map((item) => {
      if (item.types.name === active) tmpData.push(item)
    })
    setDataProducts(tmpData)
    var rows = document.querySelectorAll("#table-products tr")

    rows[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName='modal-90w'
      style={{ height: "100vh" }}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>
          Tambah Produk - {data.catName} {data.brandName}
        </span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Nav variant='pills' activeKey={typeActive} key={dataType.length}>
            {dataType.map((item, key) => {
              return (
                <Nav.Item key={key} onClick={() => handleSelectType(item)}>
                  <Nav.Link eventKey={item} className='font-11'>
                    {item}
                  </Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </div>
        <div
          className='font-11 mt-2'
          style={{ overflow: "scroll", height: "72vh" }}
          hidden={dataProducts.length ? false : true}>
          <Table
            id='table-products-add'
            className='mt-3'
            style={{ width: "100%" }}
            bordered
            hover
            ref={(el) => (ref = el)}>
            <thead>
              <tr>
                <th>No</th>
                <th>Tipe</th>
                <th>Nama Produk</th>
                <th>Deskripsi Produk</th>
                <th className='text-center'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataProducts.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{item.types.name}</td>
                    <td>{item.name}</td>
                    <td>{item.desc}</td>
                    <td className='text-center'>
                      <Button
                        variant='primary'
                        className='text-white font-11 shadow-none'
                        disabled={item.isLoading}
                        onClick={() => actionAdd(item)}
                        type='button'>
                        {item.isLoading ? (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          "Tambah"
                        )}
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAdd
