import React, {useContext} from 'react'
import {Modal, Spinner} from 'react-bootstrap'   

//=========CONTEXT========//
import {AppContext} from "../../context/AppContext"  

const ModalSpinner = (props) =>{ 
    const {setSpinner} = useContext(AppContext)  

    const handleClose = () => {
       setSpinner(false)
    }

    return (
        <Modal
        {...props} 
        dialogClassName="modal-spinner"
        aria-labelledby="contained-modal-title-vcenter" 
        onHide={handleClose}
        animation={false}
        centered> 
            <div className="w-100 text-center">
                <Spinner animation="grow" />
            </div> 
        </Modal>
    )
}

export default ModalSpinner