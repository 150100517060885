import React, { useState, useEffect, useContext, useRef } from "react"
import { Modal, Button, Form, Spinner } from "react-bootstrap"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../context/AppContext"

const ModalSend = ({ show, setShow }) => {
  const [data, setData] = useState({})
  let tujuan = useRef("")
  const [isLoading, setLoading] = useState(false)
  const { api } = useContext(AppContext)

  useEffect(() => {
    if (show.row && show.row.length) {
      let tmpData = show.row
      let obj = {
        id: tmpData[0],
        code: tmpData[2],
        product: tmpData[4],
        supplier: tmpData[5],
        price: tmpData[6],
      }
      setData(obj)
    }
  }, [show])

  const send = async () => {
    let token = JSON.parse(localStorage.getItem("usergameshubcloud"))

    if (data.code === "") {
      NotificationManager.error("Kode Product harus di isi.")
      return
    }
    if (tujuan.value === "") {
      NotificationManager.error("No Tujuan harus di isi.")
      return
    }

    Object.assign(data, { tujuan: tujuan.value })
    setLoading(true)
    let transaction = await api("post", "transaction/inquiry", data)
    if (transaction.success) {
      NotificationManager.success(transaction.message)
      setShow(false)
    } else NotificationManager.error(transaction.message)
    setLoading(false)
  }
  return (
    <Modal
      show={show.show}
      onHide={() => setShow(false)}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>Transaksi</span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className='font-12'>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Kode</Form.Label>
            <Form.Control
              name='code'
              value={data.code}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Produk</Form.Label>
            <Form.Control
              name='product'
              value={data.product}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Harga</Form.Label>
            <Form.Control
              name='harga'
              value={data.price}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Supplier</Form.Label>
            <Form.Control
              name='supplier'
              readOnly
              value={data.supplier}
              className='font-11 shadow-none'
            />
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>No Tujuan</Form.Label>
            <Form.Control
              name='nominal'
              ref={(el) => (tujuan = el)}
              type='number'
              className='font-11 shadow-none'
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='shadow-none font-13'
          onClick={() => setShow(false)}>
          Batal
        </Button>
        <Button
          variant='primary'
          className='text-white font-11 shadow-none'
          disabled={isLoading}
          onClick={() => send()}
          type='button'>
          {isLoading ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            "Kirim"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSend
