import React, { useEffect, useState, useContext, useRef } from "react"
import { Col, Row, Spinner, Button, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { AppContext } from "../../context/AppContext"
import moment from "moment"
import { commafy } from "../../utils/Utils"
import Transaction from "./Transaction"

const Dashboard = () => {
  const { api, setSpinner } = useContext(AppContext)
  const [startDate, setStartDate] = useState(new Date())
  const [monthParam, setMonthParam] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    day: new Date().getDate(),
    live : false
  })
  const [endDate, setEndDate] = useState(new Date())
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  let refLive = useRef()

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    getData()
  }, [])

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const getData = async () => {
    setSpinner(true)
    let param = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      live : refLive.checked
    }
    const data = await api("get", "transaction/summary", param)
    if (data.success) {
      setData(data.data)
    }
    setSpinner(false)
  }

  const filter = () => {
    setMonthParam({
      month: startDate.getMonth(),
      year: startDate.getFullYear(),
      day: startDate.getDate(),
      live : refLive.checked
    })

    getData()
  }

  return (
    <React.Fragment>
      <div className="m-3">
        <Row className="g-2 w-100 d-flex">
          <Col
            md="2"
            className="float-right d-align-items-end justify-content-end"
          >
            <DatePicker
              selected={startDate}
              onChange={onChange}
              className="form-control font-11 shadow-none"
              startDate={startDate}
              endDate={endDate}
              selectsRange
              popperPlacement="bottom"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "5px, 10px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                },
              }}
            />
          </Col>
          <Col md="2">
            <Form.Check
              type="checkbox"
              label="Live Transaksi"
              ref={(el) => (refLive = el)}
              defaultChecked={false}
              className="mr-0 pr-0 font-12 mt-2"
            />
          </Col>
          <Col md="2">
            <Button
              variant="primary"
              className="text-white font-10 shadow-none"
              disabled={isLoading}
              onClick={() => filter()}
              type="button"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="font-10"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Filter"
              )}
            </Button>
          </Col>
        </Row>
      </div>

      <Row className="px-3">
        <Col>
          <div className="font-12 shadow p-3">
            <span className="w-100">Transaksi Sukses</span>
            <div className="border-top mt-2">
              <div className="font-14 font-w-600 mt-2">
                <span>{data.success}</span>
              </div>
              <div className="font-12 mt-2">
                <span>Rp {commafy(data.price_success)}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="font-12 shadow p-3">
            <span className="w-100">Transaksi Gagal</span>
            <div className="border-top mt-2">
              <div className="font-14 font-w-600 mt-2">
                <span>{data.gagal}</span>
              </div>
              <div className="font-12 mt-2">
                <span>Rp {commafy(data.price_gagal)}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="font-12 shadow p-3">
            <span className="w-100">Transaksi Proses</span>
            <div className="border-top mt-2">
              <div className="font-14 font-w-600 mt-2">
                <span>{data.proses}</span>
              </div>
              <div className="font-12 mt-2">
                <span>Rp {commafy(data.price_proses)}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="font-12 shadow p-3">
            <span className="w-100">Total Transaksi</span>
            <div className="border-top mt-2">
              <div className="font-14 font-w-600 mt-2">
                <span>{data.total}</span>
              </div>
              <div className="font-12 mt-2">
                <span>Rp {commafy(data.price_total)}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="font-12 shadow p-3">
            <span className="w-100">Keuntungan</span>
            <div className="border-top mt-2">
              <div className="font-14 font-w-600 mt-2">
                <span>{data.untung}</span>
              </div>
              <div className="font-12 mt-2">
                <span>Rp {commafy(data.price_untung)}</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Transaction month={monthParam} />
    </React.Fragment>
  );
}

export default Dashboard
