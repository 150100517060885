import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
// ROUTER
import { useHistory } from "react-router-dom"

const Sidebar = () => {
  const history = useHistory()
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("usergameshubcloud"))
    if (user.type === "investor") setHidden(true)
  }, [])

  return (
    <React.Fragment>
      <div className='shadow bg-white p-2' style={{ height: "100vh" }}>
        <Row className='border-bottom p-2 cursor-pointer'>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-chart-line font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/dashboard")}>
            <span className='font-11'>Dashboard</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-exchange-alt font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/transaksi")}>
            <span className='font-11'>Transaksi</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-file-alt font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/produk")}>
            <span className='font-11'>Produk</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-gift font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/voucher")}>
            <span className='font-11'>Voucher</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-wallet font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/saldo")}>
            <span className='font-11'>Saldo</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fas fa-user-friends font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/supplier")}>
            <span className='font-11'>Supplier</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fas fa-user font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/downline")}>
            <span className='font-11'>Reseller</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fa fa-link font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/setup-koneksi")}>
            <span className='font-11'>Atur Koneksi</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fas fa-cog font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/setting")}>
            <span className='font-11'>Setting</span>
          </Col>
        </Row>
        <Row className='border-bottom p-2 cursor-pointer' hidden={hidden}>
          <Col md='1' className=''>
            <span>
              <i class='fas fa-book font-14'></i>
            </span>
          </Col>
          <Col md='9' onClick={() => history.push("/app/domino")}>
            <span className='font-11'>Link Domino</span>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
