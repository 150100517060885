import React from "react"
import { Col, Row } from "react-bootstrap"
import { commafy } from "../../utils/Utils"

const Intruction = ({ showIntruction, params = { nominal: "" } }) => {
  return (
    <Col md='6' hidden={!showIntruction}>
      <div
        className='font-11 p-3 rounded'
        style={{ border: "dashed 1px #0099cc" }}>
        <div className='font-13 font-w-600 text-center '>
          <span>Request Deposit Via BCA</span>
        </div>
        <div className='text-center'>
          <p>Silahkan Selesaikan pembayaran sesuai data di bawah ini</p>
        </div>
        <div className='text-right'>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col md='4'>
              <span>Jumlah Transfer</span>
            </Col>
            <Col md='4'>
              <span>{commafy(params.nominal)}</span>
            </Col>
            <Col md='2'>
              <span className='cursor-pointer'>
                <i className='fa fa-copy'></i>
              </span>
            </Col>
          </Row>
          <Row className='mt-2 d-flex justify-content-center align-items-center'>
            <Col md='4'>
              <span>Nomor Rekening Transfer</span>
            </Col>
            <Col md='4'>
              <span>0068114333</span>
            </Col>
            <Col md='2'>
              <span className='cursor-pointer'>
                <i className='fa fa-copy'></i>
              </span>
            </Col>
          </Row>
          <Row className='mt-2 d-flex justify-content-center align-items-center'>
            <Col md='4'>
              <span>Nama Rekening</span>
            </Col>
            <Col md='4'>
              <span>PT Teknologi Data Optima</span>
            </Col>
            <Col md='2'>
              <span className='cursor-pointer'>
                <i className='fa fa-copy'></i>
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className='font-11 p-2 rounded-left'
        style={{
          borderRight: "dashed 1px #0099cc",
          borderLeft: "dashed 1px #0099cc",
          borderBottom: "dashed 1px #0099cc",
        }}>
        <p>
          Mohon untuk transfer sesuai dengan jumlah yang tertera (Tanpa
          Pembulatan atau tekan "copy"), untuk memudahkan verifikasi transfer
          deposit. Catatan: Jumlah transfer (Rp 200.517) hanya berlaku untuk
          hari ini (10-11-2021)
        </p>
      </div>
    </Col>
  )
}

export default Intruction
