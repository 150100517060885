const axios = require("axios").default

export const MakeRequest = async (method, url, data, headersParams) => {
  let pathUrl = "https://nara-store.id/api"
  if (window.location.hostname === "localhost")
    pathUrl = "http://localhost:8080/api"
  pathUrl = `${pathUrl}/`
  const path = `${pathUrl}${url}`

  let token = localStorage.getItem("usergameshubcloudtoken")

  const headers = {
    "Content-Type": "application/json",
    "api-token": `${token}`,
  }

  if (headersParams) {
    Object.assign(headersParams, { "api-token": `${token}` })
  }

  let result = await axios({
    method: method,
    url: path,
    data: data,
    params: method === "get" ? data : "",
    headers: headersParams ? headersParams : headers,
  }).catch((error) => {
    return error.response
  })

  return result ? result.data : { data: { success: false }, status: 500 }
}

export const MakeRequestLogin = async (method, url, data) => {
  let pathUrl = "https://nara-store.id"
  if (window.location.hostname === "localhost")
    pathUrl = "http://localhost:3333"
  pathUrl = `${pathUrl}/`
  const path = `${pathUrl}${url}`

  let localData = JSON.parse(localStorage.getItem("usersgameshub"))
  let token = localData ? localData.token : ""

  const headers = {
    "Content-Type": "application/json",
    "api-token": token,
  }

  let result = await axios({
    method: method,
    url: path,
    data: data,
    params: method === "get" ? data : "",
    headers: headers,
  }).catch((error) => {
    return error.response
  })

  return result ? result.data : { data: { success: false }, status: 500 }
}

export const MakeRequestDownload = async (method, url, data) => {
  let pathUrl = "https://nara-store.id/api/v1/"
  if (window.location.hostname === "localhost")
    pathUrl = "http://localhost:3333/api/v1/"
  pathUrl = `${pathUrl}/`
  const path = `${pathUrl}${url}`
  let result = await axios({
    method: method,
    url: path,
    data: data,
    responseType: "arraybuffer",
  }).catch((error) => {
    return error.response
  })

  return result ? result.data : { data: [], status: 500 }
}
