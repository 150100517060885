import React, { useContext, useEffect, useState } from "react"
import { Col, Form, Row, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
// Hook Forms
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { NotificationManager } from "react-notifications"

const Connection = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const { api, setSpinner } = useContext(AppContext)
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ mode: "onBlur" })

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    getData()
  }, [])

  const getData = async () => {
    setSpinner(true)
    let data = await api("get", "connection-ip/edit", {})
    if (data.success) {
      setData(data.data)
      setValue("development_ip", data.data.development_ip, {
        shouldValidate: false,
      })
      setValue("production_ip", data.data.production_ip, {
        shouldValidate: false,
      })
      setValue("callback_url", data.data.callback_url, {
        shouldValidate: false,
      })
      setValue("type", data.data.type, {
        shouldValidate: false,
      })
    }
    setSpinner(false)
  }

  const onSubmit = async (params) => {
    if (params.type === "Pilih Tipe Koneksi") {
      setError("type", {
        type: "manual",
        message: "Silahkan pilih tipe koneksi",
      })
      return
    }
    setLoading(true)
    let data = await api("put", "connection-ip/update", params)
    if (data.success) {
      NotificationManager.success(data.message)
    }
    setLoading(false)
  }

  return (
    <div className='p-3'>
      <Row>
        <Col md='6'>
          <div className='font-13 font-w-600'>
            <span>Informasi Pengguna</span>
          </div>
          <div className='mt-1'>
            <Form.Group className='font-11'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                className='font-11 shadow-none'
                value={data.username}
                readOnly
              />
            </Form.Group>
            <Form.Group className='font-11 mt-2'>
              <Form.Label>Development Key</Form.Label>
              <Form.Control
                className='font-11 shadow-none'
                readOnly
                value={data.development_key}
              />
            </Form.Group>
            <Form.Group className='font-11 mt-2'>
              <Form.Label>Production Key</Form.Label>
              <Form.Control
                className='font-11 shadow-none'
                readOnly
                value={data.production_key}
              />
            </Form.Group>
            <Form.Group className='font-11 mt-2'>
              <Form.Label>
                Production Public Key (for mobile / web integration)
              </Form.Label>
              <Form.Control
                className='font-11 shadow-none'
                readOnly
                value={data.production_key_public}
              />
            </Form.Group>
          </div>

          <div className='font-13 font-w-600 mt-4'>
            <span>Whitelist IP</span>
          </div>
          <div className='mt-1'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Development IP</Form.Label>
                <Form.Control
                  name='development_ip'
                  className='font-11 shadow-none'
                  {...register("development_ip", {
                    required: "Development IP is required",
                  })}
                />
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='development_ip' />
              </div>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Production IP</Form.Label>
                <Form.Control
                  className='font-11 shadow-none'
                  {...register("production_ip", {
                    required: "Production IP is required",
                  })}
                />
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='production_ip' />
              </div>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Tipe Koneksi</Form.Label>
                <Form.Select
                  defaultValue={data.type}
                  {...register("type", {})}
                  className='font-11 shadow-none'>
                  <option defaultValue={data.type}>Pilih Tipe Koneksi</option>
                  <option
                    value='API'
                    selected={data.type === "API" ? true : false}>
                    API
                  </option>
                  <option
                    value='Otomax'
                    selected={data.type === "Otomax" ? true : false}>
                    Otomax
                  </option>
                </Form.Select>
              </Form.Group>
              <div className='mt-1 text-left font-11 text-danger'>
                <ErrorMessage errors={errors} name='type' />
              </div>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Callback URL</Form.Label>
                <Form.Control
                  className='font-11 shadow-none'
                  {...register("callback_url", {})}
                />
              </Form.Group>
              <Form.Group className='font-11 mt-3'>
                <Button
                  variant='primary'
                  className='text-white font-11 shadow-none'
                  disabled={isLoading}
                  type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    "Simpan"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Connection
