import React, { useContext, useEffect, useRef, useState } from "react"
import { Table, Row } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import { commafy } from "../../utils/Utils"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import moment from "moment"

const $ = require("jquery")
$.Datatable = require("datatables.net")

const Downline = () => {
  let ref = useRef()
  useEffect(() => {}, [])
  const { api, setSpinner } = useContext(AppContext)
  const [data, setData] = useState([])

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    getData()
  }, [])

  const getData = async () => {
    setSpinner(true)
    const data = await api("get", "user", {})
    if (data.success) {
      setData(data.data)
    }
    setSpinner(false)
  }

  useEffect(() => {
    const element = $(ref)
    if (!element[0].id) return
    if ($.fn.dataTable.isDataTable(element)) {
      element.DataTable().destroy()
    }
    let initial = {
      columns: [
        { title: "No" },
        { title: "Nama" },
        { title: "Email" },
        { title: "Nama Usaha" },
        { title: "No Hp" },
        { title: "Saldo" },
        { title: "Tgl Daftar" },
      ],
      data: [],
      aaSorting: [],
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4, 6],
          orderable: false,
        },
        {
          targets: [5],
          orderable: true,
        },
      ],
      autoWidth: false,
      pageLength: 100,
      fixedColumns: true,
    }
    let obj = []
    data.forEach((item, key) => {
      let tmp = [
        key + 1,
        item.first_name,
        item.email,
        item.business_name,
        item.phone,
        item.saldo ? commafy(item.saldo.nominal) : 0,
        moment(item.createdAt).format("DD-MM-YYYY hh:mm:ss"),
      ]
      obj.push(tmp)
    })

    initial.data = obj
    let table = element.DataTable(initial)
  }, [data])

  return (
    <div className='p-3'>
      <Row>
        <div className='font-11' style={{ height: "89vh", overflow: "scroll" }}>
          <div className='font-13 font-w-600'>
            <span>Reseller</span>
          </div>

          <div className='mt-4'>
            <div>
              <Table
                id='table-supplier'
                style={{ width: "100%" }}
                bordered
                hover
                ref={(el) => (ref = el)}></Table>
            </div>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Downline
