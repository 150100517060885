import React, { useContext, useEffect, useState, useRef } from "react";
import { Row, Table, Button, Nav } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { asyncRequest, commafy } from "../../utils/Utils";
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css";
import ModalAdd from "./ModalAdd";
import ModalDelete from "../../components/modals/ModalDelete";
import { NotificationManager } from "react-notifications";
import ModalSupplier from "./ModalSupplier";
import _ from "lodash";
import ModalSend from "./ModalSend";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const Products = ({}) => {
  let ref = useRef();
  let refTable = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showModalSupplier, setShowModalSupplier] = useState({
    show: false,
    id: "",
    row: { supplier: {} },
  });
  const [showModalSend, setShowModalSend] = useState({
    show: false,
    id: "",
    row: { supplier: {} },
  });
  const [showModalDelete, setShowModalDelete] = useState({
    show: false,
    name: "",
    id: "",
  });
  const [dataBrand, setDataBrand] = useState([]);
  const [dataType, setDataType] = useState([]);
  const { api, setSpinner } = useContext(AppContext);
  const [dataCategory, setDataCategory] = useState([]);
  const [idCategory, setIdCategory] = useState("");
  const [brandActive, setBrandActive] = useState("");
  const [typeActive, setTypeAtive] = useState("");
  const [typeActiveName, setTypeAtiveName] = useState("");
  const [brandNameActive, setBrandNameActive] = useState("");
  const [categoryActive, setCategoryActive] = useState("Pulsa");
  const [dataProducts, setDataProducts] = useState([]);
  let supplierId = useRef("");

  useEffect(() => {
    if (!showModal && idCategory)
      getDataProduct(idCategory, brandActive, typeActive);
  }, [showModal]);

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken");
    if (!token) {
      window.location.href = "/login";
      return;
    }
    if (!showModal) getDataCategory();
  }, []);

  const getDataCategory = async () => {
    setDataBrand([]);
    setDataType([]);
    setDataProducts([]);
    setSpinner(true);
    const data = await api("get", "category/get", {});
    if (data.success) {
      data.data.map((item) => {
        if (item.name === categoryActive) {
          setIdCategory("");
          setIdCategory(item.id);
        }
      });
      setDataCategory(data.data);
    }
    setSpinner(false);
  };

  const getDataBrand = async (category_id) => {
    setDataBrand([]);
    setDataType([]);
    setDataProducts([]);
    setSpinner(true);
    const data = await api("get", "brands-category/get", {
      category_id: category_id,
    });
    if (data.success) {
      data.data.map((item, key) => {
        if (key === 0 && brandActive === "") {
          setBrandActive(item.brand_id);
          setBrandNameActive(item.name);
        }
      });
      setDataBrand(data.data);
    }
    setSpinner(false);
  };

  const getDataType = async (brand_id) => {
    setDataProducts([]);
    setSpinner(true);
    const data = await api("get", "brands-type/get", {
      brand_id: brand_id,
      category_id: idCategory,
    });
    if (data.success) {
      data.data.map((item, key) => {
        if (key === 0 && typeActive === "") {
          setTypeAtive(item.type_id);
          setTypeAtiveName(item.name);
        }
      });
      setDataType(data.data);
      if (typeActive !== "" && data.data.length) {
        getDataProduct(idCategory, brandActive, typeActive);
      }
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (brandActive === "") return;
    getDataProduct(idCategory, brandActive, typeActive);
  }, [typeActive]);

  useEffect(() => {
    if (brandActive === "") return;
    getDataType(brandActive);
  }, [brandActive]);

  useEffect(() => {
    if (idCategory === "") return;
    getDataBrand(idCategory);
  }, [idCategory]);

  const getDataProduct = async (category_id, brand_id, type_id) => {
    if (type_id === "") return;
    setSpinner(true);
    const data = await api("get", "produk", {
      category_id: category_id,
      brand_id: brand_id,
      type_id: type_id,
    });

    if (data.success) {
      setDataProducts(data.data);
    }
    setSpinner(false);
  };

  const handleSelect = (item) => {
    setSpinner(true);
    setCategoryActive(item.name);
    supplierId.current = "";
    setTypeAtive("");
    setTypeAtiveName("");
    setBrandActive("");
    setDataProducts([]);
    setIdCategory(item.id);
  };

  useEffect(() => {
    const element = $(ref);
    if (!element[0].id) return;
    if ($.fn.dataTable.isDataTable(element)) {
      element.DataTable().destroy();
    }
    let initial = {
      columns: [
        { title: "Id" },
        { title: "No" },
        { title: "Kode" },
        { title: "Type" },
        { title: "Produk" },
        { title: "Supplier" },
        { title: "Harga" },
        { title: "Margin" },
        { title: "Status" },
        { title: "Action" },
        { title: "SupplierId" },
        { title: "CodeSupplier" },
        { title: "UID" },
      ],
      data: [],
      aaSorting: [],
      // processing: true,
      // serverSide: true,
      // ajax: "https://gc.acceleratestudio.net/api/category/get",
      columnDefs: [
        { width: "5%", targets: 1 },
        { width: "10%", targets: 2 },
        { width: "10%", targets: 3 },
        { width: "20%", targets: 4 },
        { width: "20%", targets: 5 },
        { width: "10%", targets: 6 },
        { width: "10%", targets: 7 },
        { width: "7%", targets: 8 },
        { width: "10%", targets: 9 },
        {
          className: "dt-center",
          targets: [7, 8],
        },

        {
          targets: [0, 1, 2, 3, 4, 5, 7, 8, 9],
          orderable: false,
        },
        {
          targets: [0, 10, 11,12],
          visible: false,
        },
        {
          targets: [2],
          className: "text-left",
          render: function (data, type, row, meta) {
            return `<input type="text" name="code"  style="text-transform:uppercase" class="form-control shadow-none font-11" value=${
              data ? data : "-"
            } />`;
          },
        },
        {
          targets: [7],
          className: "text-left",
          render: function (data, type, row, meta) {
            return `<input type="text" name="margin"  style="text-transform:uppercase" class="form-control shadow-none font-11" value=${
              data ? data : "0"
            } />`;
          },
        },
        {
          targets: [5],
          className: "text-left",
          render: function (data, type, row, meta) {
            return `<div><div class="d-inline-block w-75"><span className="mr-3">${data}</span></div><div class="d-inline-block w-25 text-right" style="text-align:right"><span style="margin-right:10px !important"><i class="fa fa-edit text-primary cursor-pointer font-12" id="edit"></i></span></div></div>`;
          },
        },
        {
          targets: [8],
          className: "text-center",
          render: function (data, type, row, meta) {
            return ` <div class="form-check form-switch d-flex justify-content-center">
                      <input class="form-check-input shadow-none font-12" name="status" type="checkbox" id="flexSwitchCheckChecked" ${
                        data === true ? "checked" : ""
                      }> 
                    </div>`;
          },
        },
        {
          targets: [9],
          className: "text-center",
          render: function (data, type, row, meta) {
            return `<div>
            <div class="d-inline-block"><span style="margin-right:10px !important"><i id="send" class="fa fa-paper-plane  cursor-pointer text-primary ml-3 font-12"></i></span></div>
            <div class="d-inline-block mr-5"><span style="margin-right:10px !important"><i class="fa fa-save text-primary cursor-pointer font-13 ml-3" id="save"></i></span></div>
            <div class="d-inline-block"><span><i id="delete" class="fa fa-trash cursor-pointer text-danger ml-3 font-12"></i></span></div>
          
            </div>`;
          },
        },
      ],
      autoWidth: false,
      pageLength: 100,
    };
    let obj = [];
    dataProducts.forEach((item, key) => {
      let tmp = [
        item.id,
        key + 1,
        item.code ? item.code : "",
        item.type.name,
        item.name,
        item.supplier ? item.supplier.name : "-",
        commafy(item.price),
        item.margin,
        item.is_active,
        "",
        item.supplier_id,
        item.code_supplier ? item.code_supplier : "-",
        item.product_uid
      ];
      obj.push(tmp);
    });

    initial.data = obj;
    let table = element.DataTable(initial);
    refTable.current = table;
    element.on("click", "tr", function (e) {
      let dataRow = table.row(this).data(); 
      if (e.target.id === "delete")
        setShowModalDelete({
          show: true,
          name: dataRow && dataRow[3],
          id: dataRow && dataRow[0],
        });
      if (e.target.id === "edit")
        setShowModalSupplier({
          id: dataRow && dataRow[0],
          show: true,
        });

      if (e.target.id === "send")
        setShowModalSend({
          row: dataRow && dataRow,
          show: true,
        });
      if (e.target.id === "save") {
        var inputData = table.$('input[name="code"]');
        var margin = table.$('input[name="margin"]');
        var checkbox = table.$('input[name="status"]');
        let idx = $(this).closest("tr").index();
        if (dataRow && dataRow[10]) supplierId.current = dataRow && dataRow[10];

        actionSave(
          dataRow && dataRow[0],
          inputData[idx] && inputData[idx].value,
          checkbox[idx] && checkbox[idx].checked,
          margin[idx] && margin[idx].value,
          dataRow && dataRow[11],
          dataRow && dataRow[10]
        );
      }
    });
  }, [dataProducts]);

  const handleSelectBrand = (item) => {
    setTypeAtive("");
    setTypeAtiveName("");
    supplierId.current = 0;
    setBrandActive(item.brand_id);
    setBrandNameActive(item.name);
  };

  const handleSelectType = (item) => {
    supplierId.current = 0;
    setTypeAtive(item.type_id);
    setTypeAtiveName(item.name);
  };

  const deleteProduct = async () => {
    setSpinner(true);
    const data = await api("post", "produk/delete", {
      id: showModalDelete.id,
    });
    if (data.success) {
      NotificationManager.success(data.message);
      getDataProduct(idCategory, brandActive, typeActive);
    } else NotificationManager.error(data.message);

    setSpinner(false);
  };

  const actionSave = async (
    id,
    code,
    status,
    margin,
    code_supplier,
    supplier_id
  ) => {
    if (id === undefined) return;
    if (code === " " || code === "" || code === "-") {
      NotificationManager.error("Kode harus di isi");
      return;
    }

    if (supplier_id === 0 || supplier_id === "") {
      NotificationManager.error("Supplier harus di pilih");
      return;
    }
    setSpinner(true);
    const data = await api("post", "produk/update", {
      id,
      code,
      status,
      supplier_id: supplier_id,
      margin,
      code_supplier,
    });
    if (data.success) {
      NotificationManager.success(data.message);
      getDataProduct(idCategory, brandActive, typeActive);
    } else NotificationManager.error(data.message);

    setSpinner(false);
  };

  const getLink = async () => {
    setSpinner(true);
    const data = await api("get", "produk/get-link");
    if (data.success) {
      window.open(window.location.href + "/" + data.data, "_blank");
    }
    setSpinner(false);
  };
  
  useEffect(() => {
    if (!showModalSupplier.id) return;
    let price = showModalSupplier.row[4];
    let supplierName = showModalSupplier.row[2];
    let code = showModalSupplier.row[3];
    let supplier_id = showModalSupplier.row[1];

    let tmpData = _.cloneDeep(dataProducts);
    tmpData.map((item) => {
      if (item.id === showModalSupplier.id) {
        item.price = price;
        item.supplier_id = supplier_id;
        item.code_supplier = code;
        if (!item.supplier)
          Object.assign(item, { supplier: { name: supplierName } });
        else item.supplier.name = supplierName;
      }
    });

    setDataProducts(tmpData);
  }, [showModalSupplier.row]);

  return (
    <div className="p-3">
      <ModalSupplier
        show={showModalSupplier}
        setShow={(e, row, id) => setShowModalSupplier({ show: e, id: id, row })}
      />
      <ModalAdd
        show={showModal}
        data={{
          brand: brandActive,
          category: idCategory,
          catName: categoryActive,
          brandName: brandNameActive,
          type: typeActive,
          typeName: typeActiveName,
        }}
        setShow={setShowModal}
      />
      <ModalSend show={showModalSend} setShow={setShowModalSend} />
      <ModalDelete
        show={showModalDelete}
        setShow={(e) => setShowModalDelete({ show: e, name: "" })}
        deleteProduct={() => deleteProduct()}
      />
      <Row>
        <div className="font-11" style={{ height: "80vh" }}>
          <div className="font-13 font-w-600">
            <span>Daftar Produk</span>
          </div>

          <div className="mt-3">
            <Nav variant="tabs" defaultActiveKey={categoryActive}>
              {dataCategory.map((item, key) => {
                return (
                  <Nav.Item key={key} onClick={() => handleSelect(item)}>
                    <Nav.Link eventKey={item.name} className="font-11">
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>

          <div className="mt-3">
            <Nav variant="pills" activeKey={brandActive}>
              {dataBrand.map((item, key) => {
                return (
                  <Nav.Item key={key} onClick={() => handleSelectBrand(item)}>
                    <Nav.Link eventKey={item.brand_id} className="font-11">
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>

          <div className="mt-3">
            <Nav variant="pills" activeKey={typeActive}>
              {dataType.map((item, key) => {
                return (
                  <Nav.Item key={key} onClick={() => handleSelectType(item)}>
                    <Nav.Link eventKey={item.type_id} className="font-11">
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <div>
            <Button
              variant="outline-primary"
              className="font-11 shadow-none mt-3"
              style={{ marginRight: "10px" }}
              type="submit"
              onClick={() => setShowModal(true)}
            >
              Tambah Produk
            </Button>
            <Button
              variant="outline-primary"
              className="font-11 shadow-none mt-3"
              type="submit"
              onClick={() => getLink()}
            >
              Get Link Product
            </Button>
          </div>

          <div className="mt-4">
            <div style={{ overflow: "scroll", paddingBottom: "100px" }}>
              <Table
                id="table-products"
                style={{ width: "100%" }}
                bordered
                hover
                ref={(el) => (ref = el)}
              ></Table>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Products;
