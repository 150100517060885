import React, { useContext } from "react"
import NavbarComponent from "../../components/NavbarComponent"
import { Route, Switch, BrowserRouter as Router } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import Sidebar from "../../components/Sidebar"
import Dashboard from "../dashboard/Dashboard"
import Connection from "../connections/Connection"
import ModalSpinner from "../../components/spinner/ModalSpinner"
import { AppContext } from "../../context/AppContext"
import Balance from "../balance/Balance"
import Products from "../products/Products"
import Transaction from "../transactions/Transaction"
import Supplier from "../supplier/Supplier"
import Downline from "../downline/Downline"
import Vouchers from "../vouchers/Vouchers"
import Settings from "../settings/Settings"
import Domino from "../domino/Domino"

const Home = () => {
  const { spinner } = useContext(AppContext)
  return (
    <Router>
      <ModalSpinner show={spinner} />
      <NavbarComponent />
      <Row className='g-2' style={{ background: "#f4f5fa" }}>
        <Col>
          <Sidebar />
        </Col>
        <Col md='10'>
          <div
            className='bg-white shadow'
            style={{ overflowY: "scroll", height: "100vh" }}>
            <Switch>
              <Route exact path='/app/dashboard' exact>
                <Dashboard />
              </Route>
              <Route exact path='/app/transaksi' exact>
                <Transaction />
              </Route>
              <Route exact path='/app/produk' exact>
                <Products />
              </Route>
              <Route exact path='/app/supplier' exact>
                <Supplier />
              </Route>
              <Route exact path='/app/downline' exact>
                <Downline />
              </Route>
              <Route exact path='/app/setup-koneksi' exact>
                <Connection />
              </Route>
              <Route exact path='/app/voucher' exact>
                <Vouchers />
              </Route>
              <Route exact path='/app/saldo' exact>
                <Balance />
              </Route>
              <Route exact path='/app/setting' exact>
                <Settings />
              </Route>
              <Route exact path='/app/domino' exact>
                <Domino />
              </Route>
            </Switch>
          </div>
        </Col>
      </Row>
    </Router>
  )
}

export default Home
