import React, { createContext, useEffect, useState } from "react"
import { MakeRequest, MakeRequestLogin } from "../api/Api"

const AppContext = createContext()
const AppContextProvider = (props) => {
  const [spinner, setSpinner] = useState(false)

  //=====HOME=====//
  const login = async (method, path, params) => {
    const result = await MakeRequestLogin(method, path, params)
    return result
  }

  const api = async (method, path, params, headers) => {
    const result = await MakeRequest(method, path, params, headers)
    return result
  }

  return (
    <AppContext.Provider
      value={{
        api,
        login,
        spinner,
        setSpinner,
      }}>
      {props.children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
