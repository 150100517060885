import React from "react"
import Highcharts from "highcharts/highcharts.js"
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js"
import HighchartsReact from "highcharts-react-official"

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const BasicChart = (props) => {
  let charts = props.charts
  const options = {
    chart: {
      type: charts.type,
      animation: true,
    },
    tooltip: charts.tooltip,
    title: {
      text: "",
    },

    xAxis: {
      categories: charts.categories,
      labels: {
        formatter() {
          return `<span style="color: black;font-size: 10px">${this.value}</span>`
        },
      },
    },
    legend: charts.legend,
    yAxis: charts.yAxis
      ? charts.yAxis
      : {
          title: {
            text: "",
          },
          labels: {
            enabled: true,
            style: {
              color: "black",
            },
          },
        },
    plotOptions: charts.plotOptions,
    credits: {
      enabled: false,
    },
    series: charts.data,
  }

  return (
    <HighchartsReact
      ref={props.ref}
      allowChartUpdate={props.ref}
      containerProps={{
        style: { height: "100%", width: "100%", margin: "0 auto" },
      }}
      highcharts={Highcharts}
      options={options}
    />
  )
}

export const AreaSpline = (props) => {
  let charts = props.charts
  const options = {
    chart: {
      type: charts.type,
      margin: 0,
      animation: true,
      backgroundColor: "transparent",
      borderColor: "black",
    },
    tooltip: charts.tooltip,
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
      categories: charts.categories,
      labels: {
        formatter() {
          return `<span style="color: black;font-size: 10px">${this.value}</span>`
        },
      },
    },
    legend: charts.legend,
    yAxis: charts.yAxis
      ? charts.yAxis
      : {
          title: {
            text: "",
          },
          visible: false,
          labels: {
            enabled: false,
            style: {
              color: "black",
            },
          },
        },
    plotOptions: charts.plotOptions,
    credits: {
      enabled: false,
    },
    series: charts.data,
  }

  return (
    <HighchartsReact
      ref={props.ref}
      allowChartUpdate={props.ref}
      containerProps={{
        style: { height: "100%", width: "100%", margin: "0 auto" },
      }}
      highcharts={Highcharts}
      options={options}
    />
  )
}

export const SplineChart = (props) => {
  let charts = props.charts

  const options = {
    chart: {
      type: charts.type,
      animation: true,
      backgroundColor: "#1A1A1A",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: charts.categories,
      labels: {
        formatter() {
          return `<span style="color: white;font-size: 10px">${this.value}</span>`
        },
      },
    },
    legend: charts.legend,
    yAxis: {
      type: "logarithmic",
      minorTickInterval: 1,
      title: {
        text: "",
      },
      labels: {
        enabled: true,
        style: {
          color: "white",
        },
      },
    },
    plotOptions: charts.plotOptions,
    credits: {
      enabled: false,
    },
    series: charts.data,
  }

  return (
    <HighchartsReact
      containerProps={{
        style: { height: "100%", width: "100%", margin: "0", padding: "0" },
      }}
      highcharts={Highcharts}
      options={options}
    />
  )
}

export const GaugeChart = (props) => {
  let charts = props.charts
  const options = {
    chart: {
      type: charts.type,
      animation: true,
      backgroundColor: "transparent",
    },
    title: null,
    pane: {
      center: ["50%", "50%"],
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.background2) || "#EEE",
        innerRadius: "70%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: true,
      shared: true,
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      minorTickInterval: null,
      tickPixelInterval: 100,
      tickWidth: 0,
      title: {
        y: -70,
      },
      labels: {
        enabled: false,
      },
    },
    plotOptions: charts.plotOptions,
    credits: {
      enabled: false,
    },

    series: charts.data,
  }

  return (
    <HighchartsReact
      containerProps={{ className: "gauge-container" }}
      highcharts={Highcharts}
      options={options}
    />
  )
}

export const Waterfall = (props) => {
  let charts = props.charts
  const options = {
    chart: {
      type: charts.type,
      animation: true,
    },
    tooltip: charts.tooltip,
    title: {
      text: "",
    },
    xAxis: {
      categories: charts.categories,
      labels: {
        formatter() {
          return `<span style="color: black;font-size: 10px">${this.value}</span>`
        },
      },
    },
    legend: charts.legend,
    yAxis: charts.yAxis
      ? charts.yAxis
      : {
          title: {
            text: "",
          },
          labels: {
            enabled: true,
            style: {
              color: "black",
            },
          },
        },
    plotOptions: charts.plotOptions,
    credits: {
      enabled: false,
    },
    series: charts.series,
  }

  return (
    <HighchartsReact
      containerProps={{
        style: { height: "100%", width: "100%", margin: "0 auto" },
      }}
      highcharts={Highcharts}
      options={options}
    />
  )
}
