import React, { useState, useContext } from "react"
// Bootrap
import {
  Row,
  Button,
  Spinner,
  Col,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap"
// Hook Forms
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
// CONTEXT
import { AppContext } from "../../context/AppContext"
// ROUTER
import { useHistory } from "react-router-dom"
// NOTIF
import { NotificationManager } from "react-notifications"

const Login = () => {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const { api } = useContext(AppContext)
  const history = useHistory()

  const onSubmit = async (params) => {
    Object.assign(params, { from: "web" })
    setLoading(true)
    let login = await api("post", "auth/login", params)

    if (login.success) {
      localStorage.removeItem("usergameshubcloud")
      localStorage.setItem("usergameshubcloudtoken", login.data.token)
      let profile = await api("get", "user/profile", {})
      if (profile.success) {
        localStorage.removeItem("usergameshubcloud")
        localStorage.setItem("usergameshubcloud", JSON.stringify(profile.data))
        window.location.href = "/app/dashboard"
      }
    } else NotificationManager.error(login.message)

    setLoading(false)
  }

  return (
    <div className='container'>
      <Row className='d-flex align-items-center justify-content-center vh-100 m-2'>
        <Col md='4' className='bg-white shadow rounded'>
          <div className='p-3 w-100'>
            <div className='text-center'>
              <span className='font-16 font-w-600 text-dark ml-3'>
                Gameshub Cloud
              </span>
            </div>

            <div className='mt-3 font-10'>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col}>
                  <InputGroup>
                    <InputGroup.Text>
                      <span>
                        <i className='fas fa-at' aria-hidden='true'></i>
                      </span>
                    </InputGroup.Text>
                    <FormControl
                      placeholder='Email'
                      aria-label='email'
                      type='email'
                      className='font-13 shadow-none'
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                  </InputGroup>
                  <div className='mt-2 text-left font-12 text-danger'>
                    <ErrorMessage errors={errors} name='email' />
                  </div>
                </Form.Group>
                <Form.Group as={Col} className='mt-3'>
                  <InputGroup>
                    <InputGroup.Text>
                      <span>
                        <i className='fa fa-eye' aria-hidden='true'></i>
                      </span>
                    </InputGroup.Text>
                    <FormControl
                      type='password'
                      placeholder='Password'
                      aria-label='password'
                      className='font-13 shadow-none'
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                  </InputGroup>
                  <div className='mt-2 text-left font-12 text-danger'>
                    <ErrorMessage errors={errors} name='password' />
                  </div>
                </Form.Group>
                <Form.Group as={Col} className='w-100 text-center mt-3'>
                  <Button
                    variant='primary'
                    className='w-100 text-white font-13 shadow-none'
                    disabled={isLoading}
                    type='submit'>
                    {isLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <div
                    className='mt-3 cursor-pointer font-12'
                    onClick={() => history.push("/register")}>
                    <span>Register</span>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
