import React, { useContext, useEffect, useState } from "react"
import { Col, Form, Row, Button, Spinner } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
// Hook Forms
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { NotificationManager } from "react-notifications"

const Settings = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const { api, setSpinner } = useContext(AppContext)
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ mode: "onBlur" })

  useEffect(() => {
    let token = localStorage.getItem("usergameshubcloudtoken")
    if (!token) {
      window.location.href = "/login"
      return
    }
    getData()
  }, [])

  const getData = async () => {
    setSpinner(true)
    let data = await api("get", "setting", { source: "digi" })
    if (data.success) {
      setData(data.data)
      setValue("session", data.data?.session, {
        shouldValidate: false,
      })
      setValue("uid", data.data?.uid, {
        shouldValidate: false,
      })

      setValue("guid", data.data?.guid, {
        shouldValidate: false,
      })
      setValue("gop", data.data?.gop, {
        shouldValidate: false,
      })
    }
    setSpinner(false)
  }

  const onSubmit = async (params) => {
    Object.assign(params, { source: "digi" })
    setLoading(true)
    let data = await api("put", "setting", params)
    if (data.success) {
      NotificationManager.success(data.message)
    }
    setLoading(false)
  }

  return (
    <div className='p-3'>
      <Row>
        <Col md='6'>
          <div className='font-13 font-w-600'>
            <span>Session Key</span>
          </div>

          <div className='mt-1'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='font-11'>
                <Form.Label>Source</Form.Label>
                <Form.Control
                  className='font-11 shadow-none'
                  value={data?.source}
                  readOnly
                />
              </Form.Group>

              <Form.Group className='font-11 mt-2'>
                <Form.Label>Session</Form.Label>
                <Form.Control
                  name='session'
                  className='font-11 shadow-none'
                  {...register("session", {
                    required: "Session IP is required",
                  })}
                />
              </Form.Group>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>X-xsrf-token</Form.Label>
                <Form.Control
                  name='x-xsrf-token'
                  className='font-11 shadow-none'
                  {...register("gop", {
                    required: "x-xsrf-token is required",
                  })}
                />
              </Form.Group>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>X-csrf-token</Form.Label>
                <Form.Control
                  name='x-csrf-token'
                  className='font-11 shadow-none'
                  {...register("guid", {
                    required: "x-csrf-token is required",
                  })}
                />
              </Form.Group>
              <Form.Group className='font-11 mt-2'>
                <Form.Label>Cookie</Form.Label>
                <Form.Control
                  name='cookie'
                  className='font-11 shadow-none'
                  {...register("uid", {
                    required: "cookie is required",
                  })}
                />
              </Form.Group>

              <Form.Group className='font-11 mt-3'>
                <Button
                  variant='primary'
                  className='text-white font-11 shadow-none'
                  disabled={isLoading}
                  type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    "Simpan"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Settings
