import React, { useEffect, useState } from "react"
import { Navbar, DropdownButton, Dropdown } from "react-bootstrap"

const NavbarComponent = () => {
  const [data, setData] = useState({ first_name: -1 })
  const logout = () => {
    localStorage.removeItem("usergameshubcloudtoken")
    localStorage.removeItem("usergameshubcloud")
    window.location.href = "/login"
  }

  useEffect(() => {
    let data = localStorage.getItem("usergameshubcloud")
    setData(JSON.parse(data))
  }, [])

  return (
    <React.Fragment>
      <Navbar bg='primary' variant='primary'>
        <div className='py-1 px-3'>
          <Navbar.Brand className='text-white font-16 font-w-600' href='/'>
            Cloud Service
          </Navbar.Brand>
        </div>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'>
          <div className='py-1 px-3 d-flex justify-content-center align-items-center'>
            <span className='text-white'>
              <i className='fa fa-user'></i>
            </span>

            <DropdownButton
              align='end'
              variant='Warning shadow-none'
              style={{ height: "25px", padding: "0px" }}
              className='shadow-none font-12 text-white m-0'>
              <Dropdown.Item className='shadow-none shadow-none font-12'>
                {data.first_name}
              </Dropdown.Item>
              <div class='dropdown-divider'></div>
              <Dropdown.Item
                className='shadow-none shadow-none font-12'
                onClick={() => logout()}
                eventKey='1'>
                Keluar
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  )
}

export default NavbarComponent
