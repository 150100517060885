import React, { useState, useEffect, useContext, useRef } from "react"
import { Modal, Button, Form, Spinner } from "react-bootstrap"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../context/AppContext"
import { commafy } from "../../utils/Utils"

const ModalProduct = ({ show, setShow }) => {
  const [data, setData] = useState({ price: "0" })
  let sn = useRef("")
  let note = useRef("")
  const [isLoading, setLoading] = useState(false)
  const [supplier, setSupllier] = useState([])
  const { api } = useContext(AppContext)
  const [action, setAction] = useState("Semua")
  const [selectedSupplier, setSelectSupplier] = useState({})

  useEffect(() => {
    if (show.row && show.row.length) {
      let tmpData = show.row

      let obj = {
        id: tmpData[1],
        code: tmpData[7],
        product: tmpData[5],
        supplier: tmpData[6],
        price: tmpData[8],
        tujuan: tmpData[4],
        status: tmpData[9],
      }
      setAction("Semua")
      setData(obj)
    }
  }, [show])

  const send = async () => {
    if (action === "alihkan") {
      data.supplier_id = selectedSupplier.supplier_id
      data.code = selectedSupplier.code
    }
    setLoading(true)
    if (action === "kirim") {
      let transaction = await api("post", "transaction/resend", data)
      if (transaction.success) {
        NotificationManager.success(transaction.message)
      } else NotificationManager.error(transaction.message)
    }
    if (action === "alihkan") {
      let transaction = await api("post", "transaction/switch", data)
      if (transaction.success) {
        NotificationManager.success(transaction.message)
      } else NotificationManager.error(transaction.message)
    }
    if (action === "gagal" || action === "sukses") {
      let request = await api("post", "transaction/update", {
        trxId: "",
        trxIdOld: data.id,
        action: action,
        note: note.value,
      })
      if (!request.success)
        NotificationManager.error(request.message, "INFO", 2000)
    }

    if (action === "ganti") {
      await api("post", "transaction/update", {
        trxId: "",
        trxIdOld: data.id,
        action: action,
        statusOld: data.status,
        sn: sn.value,
      })
    }

    if (action === "report") {
      await api("post", "transaction/report", {
        id: data.id,
      })
    }

    setShow({ show: false, refresh: true })
    setLoading(false)
  }

  useEffect(() => {
    setSelectSupplier({})
    setSupllier([])
    if (action === "Semua") return
    if (action === "alihkan") alihkan()
  }, [action])

  const alihkan = async () => {
    const dataReq = await api("get", "produk/get", { id: data.id })
    if (dataReq.success) {
      console.log(dataReq, data)
      setSupllier(dataReq.data)
    }
  }

  return (
    <Modal
      show={show.show}
      onHide={() => setShow(false)}
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>Transaksi</span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className='font-12'>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Trx Id</Form.Label>
            <Form.Control
              name='code'
              value={data.id}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>
          <Form.Group className='font-11 mt-2'>
            <Form.Label>Harga</Form.Label>
            <Form.Control
              name='code'
              value={data.price}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>

          <Form.Group className='font-11 mt-2'>
            <Form.Label>Aksi</Form.Label>
            <Form.Select
              onChange={(e) => setAction(e.target.value)}
              className='font-11 shadow-none'>
              <option value='pilih' defaultChecked={true}>
                Pilih Aksi
              </option>
              <option value='alihkan'>Alihkan Transaksi</option>
              <option value='kirim'>Kirim Ulang </option>
              <option value='sukses'>Sukseskan Transaksi</option>
              <option value='gagal'>Gagalkan Transaksi</option>
              <option value='ganti'>Ganti Serial Number</option>
              <option value='report'>Kirim Ulang Status </option>
            </Form.Select>
          </Form.Group>

          <Form.Group
            className='font-11 mt-2'
            hidden={supplier.length && action === "alihkan" ? false : true}>
            <Form.Label>Supplier</Form.Label>
            <Form.Select
              onChange={(e) => setSelectSupplier(JSON.parse(e.target.value))}
              className='font-11 shadow-none'>
              <option value='pilih' defaultChecked={true}>
                Pilih Supplier
              </option>
              {supplier.map((item, key) => {
                return (
                  <option key={key} value={JSON.stringify(item)}>
                    {item.supplier.name}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group
            className='font-11 mt-2'
            hidden={
              selectedSupplier.price && action === "alihkan" ? false : true
            }>
            <Form.Label>Harga</Form.Label>
            <Form.Control
              name='code'
              value={commafy(selectedSupplier.price)}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>

          <Form.Group
            className='font-11 mt-2'
            hidden={
              selectedSupplier.price && action === "alihkan" ? false : true
            }>
            <Form.Label>Selisih</Form.Label>
            <Form.Control
              name='code'
              value={commafy(
                parseFloat(data.price.replace(",", "")) - selectedSupplier.price
              )}
              readOnly
              className='font-11 shadow-none'
            />
          </Form.Group>

          <Form.Group
            className='font-11 mt-2'
            hidden={action === "ganti" ? false : true}>
            <Form.Label>SN</Form.Label>
            <Form.Control
              name='code'
              ref={(el) => (sn = el)}
              className='font-11 shadow-none'
            />
          </Form.Group>

          <Form.Group
            className='font-11 mt-2'
            hidden={action === "gagal" ? false : true}>
            <Form.Label>Note</Form.Label>
            <Form.Control
              name='note'
              required
              ref={(el) => (note = el)}
              className='font-11 shadow-none'
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='shadow-none font-13'
          onClick={() => setShow(false)}>
          Batal
        </Button>
        <Button
          variant='primary'
          className='text-white font-11 shadow-none'
          disabled={isLoading}
          onClick={() => send()}
          type='button'>
          {isLoading ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            "Kirim"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalProduct
