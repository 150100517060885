import React, { useState, useEffect, useContext } from "react"
import { Modal, Button } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"

const ModalDelete = ({ show, setShow, deleteProduct }) => {
  return (
    <Modal
      show={show.show}
      onHide={() => setShow(false)}
      centered
      aria-labelledby='example-custom-modal-styling-title'>
      <Modal.Header>
        <span className='font-13'>Konfirmasi</span>
        <span className='cursor-pointer' onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className='font-12'>
          <span>Hapus data {show.name}?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='shadow-none font-13'
          onClick={() => setShow(false)}>
          Batal
        </Button>
        <Button
          variant='primary'
          className='shadow-none text-white font-13'
          onClick={() => {
            setShow(false)
            deleteProduct()
          }}>
          Hapus
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDelete
