import React, { useContext, useEffect, useState, useRef } from "react"
import { Row, Table, Button } from "react-bootstrap"
import { AppContext } from "../../context/AppContext"
import { commafy } from "../../utils/Utils"
//=====DATATABLE=====//
import "../../styles/datatables/dataTables.bootstrap4.min.css"
import moment from "moment"
import ModalUpload from "./ModalUpload"

const $ = require("jquery")
$.Datatable = require("datatables.net")

const Vouchers = () => {
  let ref = useRef()
  const [data, setData] = useState([])
  const { api, setSpinner } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [isRefresh, setRefresh] = useState(true)

  useEffect(() => {
    if (isRefresh) getData()
  }, [isRefresh])

  const getData = async () => {
    setSpinner(true)
    let data = await api("get", "voucher", {})
    if (data.success) {
      setRefresh(false)
      setData(data.data)
    }
    setSpinner(false)
  }

  useEffect(() => {
    if (!data.length) return
    const element = $(ref)
    if (!element[0].id) return
    if (!$.fn.dataTable.isDataTable(element)) {
      element.DataTable({
        pageLength: 100,
        ordering: false,
        paLengthMenu: [
          [5, 10, 50, 100, 500, -1],
          [5, 10, 50, 100, 500, "All"],
        ],
        iDisplayLength: 100,
      })
    }
  }, [data])

  return (
    <div className='p-3'>
      <ModalUpload
        show={showModal}
        setRefresh={() => getData()}
        setShow={() => setShowModal(false)}></ModalUpload>
      <Row>
        <div className='font-11'>
          <div className='font-13 font-w-600'>
            <span>Voucher</span>
          </div>
          <Button
            variant='primary'
            className='font-11 shadow-none mt-3 text-white'
            style={{ marginRight: "10px" }}
            type='submit'
            onClick={() => setShowModal(true)}>
            Upload Voucher
          </Button>
          <div
            className='mt-3 pb-5'
            style={{ height: "100vh", overflow: "scroll" }}>
            <Table
              id='table-voucher'
              style={{ width: "100%" }}
              bordered
              hover
              ref={(el) => (ref = el)}>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Voucher</th>
                  <th>Denom</th>
                  <th>Status</th>
                  <th>Game</th>
                  <th>Source</th>
                  <th>Tgl Upload</th>
                  <th>Tgl Update</th>
                  <th>Trx Id</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{item.voucher}</td>
                      <td>{item.denom}</td>
                      <td>{item.status}</td>
                      <td>{item.game}</td>
                      <td>{item.source}</td>
                      <td>{item.createdAt}</td>
                      <td>{item.updatedAt}</td>
                      <td>{item.trxid}</td>
                      <td>{item.notes}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Vouchers
